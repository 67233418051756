// import React, { ReactElement, useState } from "react";

import { Box, Center } from "@chakra-ui/react";

// ** import from components pages
import { Nav } from "../../components/navbar";
import Hero from "../../components/hero";
import PagecomponentPaymentSistem from '../../components/payment-sistem'
import Solution from "../../components/solution";
import Functionalities from "../../components/functionalities";
import HowWork from "../../components/how-work";
import { FAQ } from "../../components/faq";
import Partnership from "../../components/partnerships"
import JoinUs from "../../components/join-us";
import PerfilMyWay from "../../components/perfil-myway";
import Footer from "../../components/footer";

export default function Home() {

    return (
        <Center flexDir="column"
            overflow='hidden'
            backgroundPosition="center 0"
            backgroundSize="cover"
            w="100%"
            h='100%'
            backgroundImage='linear-gradient(#2FAFF6, #47BAEB, #DAFFA5)'
        >
            <Nav />
            <Box h='100%' w='100%' overflow='hidden'>
                <Hero />
                <PagecomponentPaymentSistem />
                <Solution />
                <Box w='100%' h='100%'
                    backgroundImage='linear-gradient( #3eb6ef 1.50%, #FF9397 32.7%, #FFA8FC 100%)'
                >
                    <HowWork />
                    <Functionalities />
                    <PerfilMyWay />
                    <Partnership />
                    <JoinUs />
                    <FAQ />
                </Box>
            </Box>
            <Footer />
        </Center>
    );
}
