import React from 'react'
// import ReactPlayer from 'react-player'

import {
    Flex, Center, Image, Text, AspectRatio
    //  Icon, Container , Show,  , useBoolean
} from "@chakra-ui/react";

import GroupImage3 from '../../assets/images/pages/GroupImage3.svg'
// import Slide1MP4 from '../../assets/MP4/slide1.mp4'
// import sourceBASE from '../../assets/images/pages/howWorkBASEGIF1.svg'
// import secondImage from '../../assets/images/pages/howWorkGIF2.svg'
// import secondImageBASE from '../../assets/images/pages/howWorkGIF2BASE.svg'
// import thirdImage from '../../assets/images/pages/howWorkGIF3.svg'
// import thirdImageBASE from '../../assets/images/pages/howWorkGIF3BASE.svg'

// import thubnailImage from '../../assets/images/pages/howWorkGIF1.svg'

// import { PlayIcon } from '../../assets/svg';

export default function HowWork() {

    // const [playing, setPlaying] = useBoolean()
    // const [playingSecondVideo, setPlayingSecondVideo] = useBoolean()
    // const [playingThyrdVideo, setPlayingThyrdVideo] = useBoolean()

    return (
        <Flex w="100%"
            h='100%'
            overflow='hidden'
        >
            <Image src={GroupImage3}
                w='100%'
                maxW='1920px'
                maxH='2388px'
                ml={{ base: '0px', md: '-200px', lg: '-200px', xl: '-200px', '2xl': '-200px' }}
                position='absolute'
            />
            <Center w="100%" flexDir={'column'} zIndex={1} mt={{ base: '60px', md: '120px', lg: '44px', xl: '200px', '2xl': '349px' }}>
                <Center >
                    <Text mt={{ base: 0, lg: '55px', '2xl': 0 }}
                        fontFamily='Thyga Italic'
                        fontStyle='normal'
                        fontWeight='400'
                        fontSize={{ base: '35px', md: '60px', lg: '70px', xl: '80px', '2xl': '80px' }}
                        lineHeight='112%'
                        color='#DBFE01'
                    >
                        COMO FUNCIONA
                    </Text>
                </Center>
                <Text mt={{ base: '41px', md: '41px', lg: '41px', xl: '41px', '2xl': '41px' }}
                    fontFamily='Montserrat'
                    fontStyle='normal'
                    fontWeight='500'
                    fontSize={{ base: '15px', md: '20px', lg: '20px', xl: '20px', '2xl': '20px' }}
                    lineHeight='112%'
                    color='#000000'
                    textAlign='center'
                    alignItems='center'
                    maxW={{ base: '350px', md: '941px' }}
                    w={{ md: '700px', lg: "100%" }}
                >
                    Com o MyWAYtoPAY você vincula sua carteira de criptomoedas a um sistema de pagamento tradicional. A cada compra a carteira é escaneada e os ativos digitais são reconhecidos.
                </Text>
                <Text mt={{ base: '5px', md: '20px', lg: '20px', xl: '20px', '2xl': '20px' }}
                    fontFamily='Montserrat'
                    fontStyle='normal'
                    fontWeight='500'
                    fontSize={{ base: '15px', md: '20px', lg: '20px', xl: '20px', '2xl': '20px' }}
                    lineHeight='112%'
                    color='#000000'
                    textAlign='center'
                    alignItems='center'
                    maxW={{ base: '350px', md: '941px' }}
                    w={{ md: '700px', lg: "100%" }}
                >
                    A partir daí, você pode escolher qual NFT “pagará por aquela compra”. Além da automação do câmbio e da disponibilização de benefícios entre marca e comunidades.
                </Text>
                {/* WE USE TO SHOW MD LG XL 2XL SIZE  */}
                {/* <Show breakpoint='(min-width:440px)'> */}
                <Text w='100%' textAlign={'center'}
                    mt={{ base: '109px', md: '109px', lg: '109px', '2xl': '109px' }}
                    fontSize={{ base: '35px', md: '50px', lg: '50px', '2xl': '50px' }}
                    fontWeight='400'
                    lineHeight='112%'
                    color='#FFFFFF'
                    fontStyle='normal'
                    fontFamily='Thyga Italic'
                >
                    MEU JEITO DE PAGAR
                </Text>
                <AspectRatio mt='30px' w={{ base: '350px', md: '660px', lg: "812px" }}
                    h={{ base: '250px', md: '290px', lg: "454px" }}
                    rounded='15px'
                    ratio={1} overflow='hidden'>
                    <iframe title='Buddha Spa'
                        src='https://www.youtube.com/embed/gLPti7FySO4'
                        allowFullScreen
                    />
                </AspectRatio>

                {/* <Container> */}
                {/* <AspectRatio ratio={16 / 9} borderRadius='15px' overflow='hidden'> */}
                <>
                    {/* @ts-ignore  */}
                    {/* <video controls autoPlay loop src={Slide1MP4} type="video/mp4" /> */}

                    {/* <Center
                                    bg={thubnailImage}
                                    opacity={playing ? '0' : '1'}
                                    onClick={setPlaying.toggle}
                                    transition='all ease-in-out 0.3s'
                                    cursor='pointer'
                                    _hover={{
                                        svg: {
                                            transform: 'scale(1.3)',
                                            opacity: '0.8'
                                        }
                                    }}
                                >
                                    <Icon as={PlayIcon} w='100px' h='100px' fill='whiteAlpha.800' transition='all ease-in-out 0.3s' />
                                </Center> */}
                </>
                {/* </AspectRatio> */}
                {/* </Container> */}

                <Text w='100%' textAlign={'center'}
                    mt={{ base: '109px', md: '109px', lg: '109px', '2xl': '109px' }}
                    fontSize={{ base: '15px', md: '30px', lg: '30px', '2xl': '30px' }}
                    fontWeight='900'
                    lineHeight='112%'
                    color='#FFFFFF'
                    fontStyle='italic'
                    fontFamily='Montserrat'
                >
                    MEU SPLIT
                </Text>

                <AspectRatio mt='30px' w={{ base: '350px', md: '660px', lg: "812px" }}
                    h={{ base: '250px', md: '290px', lg: "454px" }}
                    rounded='15px'
                    ratio={1} overflow='hidden'>
                    <iframe title='Buddha Spa'
                        src='https://www.youtube.com/embed/rwY7MLIRKC0'
                        allowFullScreen
                    />
                </AspectRatio>

                <Text w='100%' textAlign={'center'}
                    mt={{ base: '139px', md: '139px', lg: '139px', '2xl': '139px' }}
                    fontSize={{ base: '15px', md: '30px', lg: '30px', '2xl': '30px' }}
                    fontWeight='900'
                    lineHeight='112%'
                    color='#FFFFFF'
                    fontStyle='italic'
                    fontFamily='Montserrat'
                >
                    MEU JEITO DE PARTILHAR
                </Text>
                <AspectRatio mt='30px' w={{ base: '350px', md: '660px', lg: "812px" }}
                    h={{ base: '250px', md: '290px', lg: "454px" }}
                    rounded='15px'
                    ratio={1} overflow='hidden'>
                    <iframe title='Buddha Spa'
                        src='https://www.youtube.com/embed/vCsjKJV6UYY'
                        allowFullScreen
                    />
                </AspectRatio>

                {/* </Show> */}
                {/* WE USE TO SHOW MD LG XL 2XL SIZE  */}
                {/* WE USE TO SHOW BASE SIZE  */}
                {/* <Show below='sm'>
                    <Image mt={{ base: '10px' }}
                        src={sourceBASE}
                        w={{ base: '100%' }}
                        maxW='350px'
                        h='100%'
                        maxH='492px'
                    />
                    <Text w='100%' textAlign={'center'}
                        mt={{ base: '45px' }}
                        fontSize={{ base: '15px' }}
                        fontWeight='900'
                        lineHeight='112%'
                        color='#FFFFFF'
                        fontStyle='italic'
                        fontFamily='Montserrat'
                    >
                        MEU SPLIT
                    </Text>
                    <Image mt={{ base: '10px' }}
                        src={secondImageBASE}
                        w={{ base: '100%' }}
                        maxW='350px'
                        h='100%'
                        maxH='492px'
                    />
                    <Text w='100%' textAlign={'center'}
                        mt={{ base: '45px' }}
                        fontSize={{ base: '15px' }}
                        fontWeight='900'
                        lineHeight='112%'
                        color='#FFFFFF'
                        fontStyle='italic'
                        fontFamily='Montserrat'
                    >
                        MEU JEITO DE PARTILHAR
                    </Text>
                    <Image mt={{ base: '10px' }}
                        src={thirdImageBASE}
                        w={{ base: '100%' }}
                        maxW='350px'
                        h='100%'
                        maxH='492px'
                    />
                </Show> */}
                {/* WE USE TO SHOW BASE SIZE  */}

            </Center>
        </Flex>

    );
}
