// import React, { ReactElement, useState } from "react";

import { Flex, Text, Image, Center, Show, Hide, Box } from "@chakra-ui/react";

// ** image to show in sistem example
import source from '../../assets/images/pages/imageFromPageSistemPayment.svg'
import sourceGIF from '../../assets/images/pages/imageFromPageSistemPayment.gif'
import sourceLG from '../../assets/images/pages/imageFromPageSistemPaymentLGSize.svg'
import sourceLGGIF from '../../assets/images/pages/imageFromPageSistemPaymentLGSize.gif'
import sourceMD from '../../assets/images/pages/imageFromPageSistemPaymentMDSize.svg'
import sourceMDGIF from '../../assets/images/pages/imageFromPageSistemPaymentMDSize.gif'

import sourceBASE from '../../assets/images/pages/imageFromPageSistemPaymentBASESize.svg'
import sourceBASEGIF from '../../assets/images/pages/imageFromPageSistemPaymentBASESize.gif'

export default function PagecomponentPaymentSistem() {
    return (
        <Box >
            {/* WE USE TO SHOW IN X2L SIZES  */}
            <Hide breakpoint='(max-width: 1440px)'>
                <Flex w="100%" mt={{ base: '5px', md: '25px', lg: '437px', xl: '0px', '2xl': '200px' }} >
                    <Center>
                        <Text w='100%'
                            //  position='absolute'
                            // mt={{ base: '5px', md: '25px', lg: '437px', xl: '0px', '2xl': '437px' }}
                            ml={{ base: '5px', md: '25px', lg: '120px', xl: '50px', '2xl': '166px' }}
                            fontFamily='Montserrat'
                            fontStyle='italic'
                            fontWeight='900'
                            fontSize={{ base: '25px', md: '50px', lg: '70px', '2xl': '70px' }}
                            lineHeight='112%'
                            color='#DFED57'
                        >
                            OS SISTEMAS <br />
                            DE PAGAMENTO <br />
                            ATUAIS NÃO <br />
                            INTEGRAM UM <br />
                            UNIVERSO TÃO <br />
                            MÚLTIPLO.
                        </Text>
                    </Center>
                    <Center>
                        <Image position='absolute'
                            // ml={{ base: '83px', md: '492px', lg: '492px', xl: '25px', '2xl': '492px' }} overflow='hidden'
                            mt={{ base: '5px', md: '25px', lg: '170px', xl: '170px', '2xl': '170px' }}
                            src={source}
                            w='100%'
                            maxW='954px'
                            maxH='1046px'
                        // bg='yellow'
                        />
                        <Image
                            // ml={{ base: '83px', md: '492px', lg: '492px', xl: '25px', '2xl': '492px' }} overflow='hidden'
                            mt={{ base: '5px', md: '25px', lg: '170px', xl: '170px', '2xl': '170px' }}
                            src={sourceGIF}
                            w='100%'
                            maxW='950px'
                            maxH='726px'
                        // bg='yellow'
                        />
                    </Center>
                </Flex>
            </Hide>
            {/* WE USE TO SHOW IN X2L SIZES  */}

            {/* WE USE TO SHOW LG and XL SIZE  */}
            <Center h='100%' w='100%' flexDir='column' display={{ base: 'none', lg: 'block', '2xl': 'none' }}  >
                <Text ml={{ lg: '120px', xl: '100px' }}
                    fontFamily='Montserrat'
                    fontStyle='italic'
                    fontWeight='900'
                    fontSize={{ base: '25px', md: '50px', lg: '70px' }}
                    lineHeight='112%'
                    color='#DFED57'
                >
                    OS SISTEMAS  DE PAGAMENTO  ATUAIS NÃO INTEGRAM UM UNIVERSO TÃO  MÚLTIPLO.
                </Text>
                <Center w='100%'>
                    <Image position='absolute'
                        mt={{ lg: '170px', xl: '25px' }}
                        src={sourceLG}
                        w='100%'
                        maxW='1247px'
                        maxH='936px'
                    />
                    <Image mt={{ lg: '25px', xl: '25px' }}
                        src={sourceLGGIF}
                        w='100%'
                        maxW='1247px'
                        maxH='936px'
                    />
                </Center>
            </Center>
            {/* WE USE TO SHOW LG and XL SIZE  */}

            {/* WE USE TO SHOW MD SIZE  */}
            <Center h='100%' w='100%' flexDir='column' display={{ base: 'none', md: 'block', lg: 'none' }} >
                <Text
                    ml={{ md: '15px' }}
                    fontFamily='Montserrat'
                    fontStyle='italic'
                    fontWeight='900'
                    fontSize={{ base: '25px', md: '50px', lg: '70px' }}
                    lineHeight='112%'
                    color='#DFED57'
                    textShadow=' 6px 7px #C05AFF'
                >
                    OS SISTEMAS  DE PAGAMENTO  ATUAIS NÃO INTEGRAM UM UNIVERSO TÃO  MÚLTIPLO.
                </Text>
                <Center w='100%' mt='150px'>
                    <Image position='absolute'
                        mt={{ md: '15px' }}
                        src={sourceMD}
                        w='100%'
                        maxW='740px'
                    />
                    <Image mt={{ md: '15px' }}
                        src={sourceMDGIF}
                        w='100%'
                        maxW='740px'
                    />
                </Center>
                {/* <GridMDFirstColum />
                <GridMDSecondColum /> */}
            </Center>
            {/* WE USE TO SHOW MD SIZE  */}

            {/* WE USE TO SHOW BASE SIZE  */}
            <Show below='sm'>
                <Center h='100%' w='100%' flexDir='column' >
                    <Text ml={{ md: '15px' }}
                        mt='25px'
                        w='100%'
                        maxW='380px'
                        fontFamily='Montserrat'
                        fontStyle='italic'
                        fontWeight='900'
                        fontSize={{ base: '30px' }}
                        lineHeight='112%'
                        color='#DFED57'
                    // textShadow=' 6px 7px #C05AFF'
                    >
                        OS SISTEMAS  DE PAGAMENTO  ATUAIS NÃO INTEGRAM UM UNIVERSO TÃO  MÚLTIPLO.
                    </Text>
                    <Center w='100%'>
                        <Image position='absolute'
                            mt={{ base: '250px' }}
                            src={sourceBASE}
                            w='100%'
                            maxW='450px'
                            h='100%'
                        />
                        <Image mt={{ base: '15px' }}
                            src={sourceBASEGIF}
                            w='100%'
                            maxW='400px'
                            h='100%'
                        />
                    </Center>
                    {/* <GridMLBASEirstColum /> */}
                </Center>
            </Show>
            {/* WE USE TO SHOW BASE SIZE  */}
        </Box>
    );
}
