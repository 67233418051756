// import React, { ReactElement, useState } from "react";

import { Flex, Text, Button, Box, Image, Show, Hide } from "@chakra-ui/react";

import source from '../../assets/images/pages/image-ilustractive-page-component-hero.svg'
import sourceSM from '../../assets/images/pages/image-ilustractive-page-component-heroSM.svg'

export default function Header() {
    return (
        <Box>
            <Flex flexDir="column"
                position='absolute'
                mt={{ base: '5px', md: '80px', lg: '208px', '2xl': '208px' }}
                ml={{ base: '5px', md: '20px', lg: '50px', xl: '50px', '2xl': '306px' }}
            >
                <Text fontSize={{ base: '35px', md: '65px', lg: '80px', xl: '75px', '2xl': '85px' }}
                    fontWeight='900'
                    lineHeight='120%'
                    color='#DFED57'
                    fontStyle='italic'
                    fontFamily='Montserrat'
                >
                    UNIVERSOS <br />INTEGRADOS <br /> DO SEU JEITO.
                </Text>
                <Text mt={{ base: '45px', md: '64px', lg: '64px', '2xl': '64px' }}
                    fontSize={{ base: '20px', md: '30px', lg: '30px', '2xl': '30px' }}
                    fontWeight='700'
                    lineHeight='112%'
                    color='#FFFFFF'
                    fontStyle='normal'
                    fontFamily='Montserrat'
                >
                    A infraestrutura de pagamentos <br /> definitiva para Web3
                </Text>
                <Button mt={{ base: '25px', md: '38px', lg: '38px', '2xl': '38px' }}
                    w={{ base: '180px', md: '250px', lg: "350px", '2xl': '376px' }}
                    h={{ base: '50px', md: '77px', lg: "77px", '2xl': '77px' }}
                    backgroundColor='#E06C82'
                    boxShadow=' 9px 8px  #7367F0'
                    variant='solid'
                    _hover={{ backgroundColor: '#E06C82' }}
                    color='#FFFFFF'
                    fontWeight='700'
                    fontSize={{ base: '12px', md: '20px', lg: '26px', xl: "26px", '2xl': '26px' }}
                    lineHeight='112%'
                    fontStyle='normal'
                    fontFamily='Montserrat'
                >
                    QUERO FAZER PARTE
                </Button>
            </Flex>
            {/* WE USE TO SHOW MD LG XL 2XL SIZE  */}
            <Show breakpoint='(min-width:1100px)'>
                <Flex justifyContent='end'>
                    <Image src={source}
                        minWidth='max-content'
                        w='100%'
                        maxW='1283px'
                        maxH='1386px'
                    />
                </Flex>
            </Show>
            <Hide breakpoint='(min-width:1100px)'>
                <Show breakpoint='(min-width:440px)'>
                    <Flex justifyContent='end'>
                        <Image src={source}
                            // marginLeft={{ md: '-100px', lg: '-80px' }}
                            minWidth='max-content'
                            w='500px'
                            h='850px'
                        />
                    </Flex>
                </Show>
            </Hide>
            {/* WE USE TO SHOW MD LG XL 2XL SIZE  */}
            {/* WE USE TO SHOW BASE SIZE  */}
            <Hide breakpoint='(min-width:440px)'>
                <Flex mt='150px' >
                    <Show below='md' >
                        <Image
                            // ml={{ base: '55px' }}
                            src={sourceSM}
                            minWidth='max-content'
                            w='100%'
                        />
                    </Show>
                </Flex>
            </Hide>
            {/* WE USE TO SHOW BASE SIZE  */}
        </Box>

    );
}
