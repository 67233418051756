import { Box, Text, Center, Grid, GridItem, Input, Select, Flex, Divider, Checkbox, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Link } from "@chakra-ui/react";

import Footer from "../../components/footer";
import { Nav } from "../../components/navbar";

export default function JoinUsPAGE() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
            <Nav />
            <Box
                // bg='red'
                backgroundImage='#F3FAFB'
            >
                <Center>
                    <Box mt={{ base: '50px', md: '120px', '2xl': '287px' }}
                        bg='#FFFFFF'
                        w='100%'
                        maxW='1280px'
                        borderRadius='6px'
                        boxShadow=' 0px 4px 51px rgba(0, 194, 203, 0.15)'
                        mb='60px'
                    >
                        <Text mt={{ base: '50px', md: '100px', '2xl': '108px' }}
                            fontFamily='Thyga Italic'
                            fontStyle='italic'
                            fontWeight='400'
                            fontSize={{ base: '35px', md: '50px' }}
                            lineHeight='112%'
                            color='#5E5873'
                            textAlign='center'
                        >
                            JUNTE-SE AO MY WAY
                        </Text>

                        <Center>
                            <Text mt={{ base: '25px', md: '50px', '2xl': '56px' }}
                                w='100%'
                                maxW='836px'
                                fontFamily='Montserrat'
                                fontStyle='normal'
                                fontWeight='400'
                                fontSize={{ base: '18px', md: '22px' }}
                                lineHeight='112%'
                                color='#5E5873'
                                textAlign='center'
                            >
                                Nos contate e faça parte dessa nova revolução nos meios de pagamento.
                                Integre comunidades, NFTs, Tokens e pagamento em fiat ou cripto.
                            </Text>
                        </Center>

                        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6} padding={{ base: '0 15px', md: '0 65px' }} mt='56px'
                            mb='65px'>
                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <>
                                    <Text mb='8px'
                                        fontFamily='Montserrat'
                                        fontStyle='normal'
                                        fontWeight='400'
                                        fontSize={'12px'}
                                        lineHeight='112%'
                                        color='#6E6B7B'
                                    >
                                        Primeiro Nome*
                                    </Text>
                                    <Input placeholder='Digite aqui' size='md' />
                                </>
                            </GridItem>

                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <>
                                    <Text mb='8px'
                                        fontFamily='Montserrat'
                                        fontStyle='normal'
                                        fontWeight='400'
                                        fontSize={'12px'}
                                        lineHeight='112%'
                                        color='#6E6B7B'
                                    >
                                        Último Nome*
                                    </Text>
                                    <Input placeholder='Digite aqui' size='md' />
                                </>
                            </GridItem>

                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <>
                                    <Text mb='8px'
                                        fontFamily='Montserrat'
                                        fontStyle='normal'
                                        fontWeight='400'
                                        fontSize={'12px'}
                                        lineHeight='112%'
                                        color='#6E6B7B'
                                    >
                                        E-mail*
                                    </Text>
                                    <Input placeholder='Digite aqui' size='md' />
                                </>
                            </GridItem>

                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <>
                                    <Text mb='8px'
                                        fontFamily='Montserrat'
                                        fontStyle='normal'
                                        fontWeight='400'
                                        fontSize={'12px'}
                                        lineHeight='112%'
                                        color='#6E6B7B'
                                    >
                                        Número do Celular*
                                    </Text>
                                    <Input placeholder='+00 (00) 00000-0000' size='md' />
                                </>
                            </GridItem>

                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <>
                                    <Text mb='8px'
                                        fontFamily='Montserrat'
                                        fontStyle='normal'
                                        fontWeight='400'
                                        fontSize={'12px'}
                                        lineHeight='112%'
                                        color='#6E6B7B'
                                    >
                                        Cargo*
                                    </Text>
                                    <Input placeholder='Digite aqui' size='md' />
                                </>
                            </GridItem>


                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <>
                                    <Text mb='8px'
                                        fontFamily='Montserrat'
                                        fontStyle='normal'
                                        fontWeight='400'
                                        fontSize={'12px'}
                                        lineHeight='112%'
                                        color='#6E6B7B'
                                    >
                                        Nome da Empresa*
                                    </Text>
                                    <Input placeholder='Digite aqui' size='md' />
                                </>
                            </GridItem>

                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <>
                                    <Text mb='8px'
                                        fontFamily='Montserrat'
                                        fontStyle='normal'
                                        fontWeight='400'
                                        fontSize={'12px'}
                                        lineHeight='112%'
                                        color='#6E6B7B'
                                    >
                                        CNPJ*
                                    </Text>
                                    <Input placeholder='Digite aqui' size='md' />
                                </>
                            </GridItem>

                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <>
                                    <Text mb='8px'
                                        fontFamily='Montserrat'
                                        fontStyle='normal'
                                        fontWeight='400'
                                        fontSize={'12px'}
                                        lineHeight='112%'
                                        color='#6E6B7B'
                                    >
                                        Tipo de Negócio*
                                    </Text>
                                    <Input placeholder='Digite aqui' size='md' />
                                </>
                            </GridItem>

                            <GridItem w='100%' >
                                <Flex justifyContent='space-between' flexDir={{ base: 'column', lg: 'row' }}>
                                    <Flex flexDir='column'>
                                        <Text mb='8px'
                                            fontFamily='Montserrat'
                                            fontStyle='normal'
                                            fontWeight='400'
                                            fontSize={'12px'}
                                            lineHeight='112%'
                                            color='#6E6B7B'
                                            textAlign='start'
                                        >
                                            Cidade*
                                        </Text>
                                        <Input htmlSize={30} width='auto' placeholder='Digite aqui' />
                                    </Flex>
                                    <Flex flexDir='column'>
                                        <Text mt={{ base: '12px', lg: 0 }}
                                            mb='8px'
                                            fontFamily='Montserrat'
                                            fontStyle='normal'
                                            fontWeight='400'
                                            fontSize={'12px'}
                                            lineHeight='112%'
                                            color='#6E6B7B'
                                        >
                                            CEP*
                                        </Text>
                                        <Input htmlSize={15} width='auto' placeholder='Digite aqui' />
                                    </Flex>

                                </Flex>
                            </GridItem>

                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <>
                                    <Text mb='8px'
                                        fontFamily='Montserrat'
                                        fontStyle='normal'
                                        fontWeight='400'
                                        fontSize={'12px'}
                                        lineHeight='112%'
                                        color='#6E6B7B'
                                    >
                                        Endereço da Empresa*
                                    </Text>
                                    <Input placeholder='Digite aqui' size='md' />
                                </>
                            </GridItem>

                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <>
                                    <Text mb='8px'
                                        fontFamily='Montserrat'
                                        fontStyle='normal'
                                        fontWeight='400'
                                        fontSize={'12px'}
                                        lineHeight='112%'
                                        color='#6E6B7B'
                                    >
                                        Estado*
                                    </Text>
                                    <Input placeholder='Digite aqui' size='md' />
                                </>
                            </GridItem>

                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <>
                                    <Text mb='8px'
                                        fontFamily='Montserrat'
                                        fontStyle='normal'
                                        fontWeight='400'
                                        fontSize={'12px'}
                                        lineHeight='112%'
                                        color='#6E6B7B'
                                    >
                                        Pais*
                                    </Text>
                                    <Select placeholder='Selecione'>
                                        <option value='option1'>Option 1</option>
                                        <option value='option2'>Option 2</option>
                                        <option value='option3'>Option 3</option>
                                    </Select>
                                </>
                            </GridItem>
                        </Grid>

                        <Divider />

                        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6} padding={{ base: '0 15px', md: '0 65px' }} mt='56px'
                            mb='65px'>
                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <>
                                    <Text mb='8px'
                                        fontFamily='Montserrat'
                                        fontStyle='normal'
                                        fontWeight='400'
                                        fontSize={'12px'}
                                        lineHeight='112%'
                                        color='#6E6B7B'
                                    >
                                        O que melhor descreve sua empresa?*
                                    </Text>
                                    <Select placeholder='Selecione'>
                                        <option value='option1'>Option 1</option>
                                        <option value='option2'>Option 2</option>
                                        <option value='option3'>Option 3</option>
                                    </Select>
                                </>
                            </GridItem>

                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <>
                                    <Text mb='8px'
                                        fontFamily='Montserrat'
                                        fontStyle='normal'
                                        fontWeight='400'
                                        fontSize={'12px'}
                                        lineHeight='112%'
                                        color='#6E6B7B'
                                    >
                                        Esta entidade possui acionistas corporativos superiores a 25%?*
                                    </Text>
                                    <Select placeholder='Selecione'>
                                        <option value='option1'>Option 1</option>
                                        <option value='option2'>Option 2</option>
                                        <option value='option3'>Option 3</option>
                                    </Select>
                                </>
                            </GridItem>

                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <>
                                    <Text mb='8px'
                                        fontFamily='Montserrat'
                                        fontStyle='normal'
                                        fontWeight='400'
                                        fontSize={'12px'}
                                        lineHeight='112%'
                                        color='#6E6B7B'
                                    >
                                        Website*
                                    </Text>
                                    <Input placeholder='Digite ou coloque a url do seu site aqui' size='md' />
                                </>
                            </GridItem>

                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <>
                                    <Text mb='8px'
                                        fontFamily='Montserrat'
                                        fontStyle='normal'
                                        fontWeight='400'
                                        fontSize={'12px'}
                                        lineHeight='112%'
                                        color='#6E6B7B'
                                    >
                                        Tipo da Solução*
                                    </Text>
                                    <Select placeholder='Selecione'>
                                        <option value='option1'>Option 1</option>
                                        <option value='option2'>Option 2</option>
                                        <option value='option3'>Option 3</option>
                                    </Select>
                                </>
                            </GridItem>

                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <>
                                    <Text mb='8px'
                                        fontFamily='Montserrat'
                                        fontStyle='normal'
                                        fontWeight='400'
                                        fontSize={'12px'}
                                        lineHeight='112%'
                                        color='#6E6B7B'
                                    >
                                        Estágio do projeto*
                                    </Text>
                                    <Select placeholder='Selecione'>
                                        <option value='option1'>Option 1</option>
                                        <option value='option2'>Option 2</option>
                                        <option value='option3'>Option 3</option>
                                    </Select>
                                </>
                            </GridItem>


                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <>
                                    <Text mb='8px'
                                        fontFamily='Montserrat'
                                        fontStyle='normal'
                                        fontWeight='400'
                                        fontSize={'12px'}
                                        lineHeight='112%'
                                        color='#6E6B7B'
                                    >
                                        Qual é o seu volume de transações mensal real/previsto?*
                                    </Text>
                                    <Select placeholder='Selecione'>
                                        <option value='option1'>Option 1</option>
                                        <option value='option2'>Option 2</option>
                                        <option value='option3'>Option 3</option>
                                    </Select>
                                </>
                            </GridItem>

                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <>
                                    <Text mb='8px'
                                        fontFamily='Montserrat'
                                        fontStyle='normal'
                                        fontWeight='400'
                                        fontSize={'12px'}
                                        lineHeight='112%'
                                        color='#6E6B7B'
                                    >
                                        Mês de lançamento
                                    </Text>
                                    <Select placeholder='Selecione'>
                                        <option value='option1'>Option 1</option>
                                        <option value='option2'>Option 2</option>
                                        <option value='option3'>Option 3</option>
                                    </Select>
                                </>
                            </GridItem>

                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <>
                                    <Text mb='8px'
                                        fontFamily='Montserrat'
                                        fontStyle='normal'
                                        fontWeight='400'
                                        fontSize={'12px'}
                                        lineHeight='112%'
                                        color='#6E6B7B'
                                    >
                                        Estão construindo na blockchain?*
                                    </Text>
                                    <Select placeholder='Selecione'>
                                        <option value='option1'>Option 1</option>
                                        <option value='option2'>Option 2</option>
                                        <option value='option3'>Option 3</option>
                                    </Select>
                                </>
                            </GridItem>
                        </Grid>

                        <Divider />

                        <Grid templateColumns={{ base: 'repeat(1, 1fr)' }} gap={6} padding={{ base: '0 15px', md: '0 65px' }} mt='56px'
                            mb='65px'>
                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <>
                                    <Text mb='8px'
                                        fontFamily='Montserrat'
                                        fontStyle='normal'
                                        fontWeight='400'
                                        fontSize={'12px'}
                                        lineHeight='112%'
                                        color='#6E6B7B'
                                    >
                                        Se você selecionou 'outro', informe-nos em qual blockchain você está construindo
                                    </Text>
                                    <Input placeholder='Digite aqui' size='md' />
                                </>
                            </GridItem>

                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <>
                                    <Text mb='8px'
                                        fontFamily='Montserrat'
                                        fontStyle='normal'
                                        fontWeight='400'
                                        fontSize={'12px'}
                                        lineHeight='112%'
                                        color='#6E6B7B'
                                    >
                                        Como ouviu falar de nós?
                                    </Text>
                                    <Input placeholder='Digite aqui' size='md' />
                                </>
                            </GridItem>

                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <>
                                    <Text mb='8px'
                                        fontFamily='Montserrat'
                                        fontStyle='normal'
                                        fontWeight='400'
                                        fontSize={'12px'}
                                        lineHeight='112%'
                                        color='#6E6B7B'
                                    >
                                        Parceiro de Referência
                                    </Text>
                                    <Input placeholder='Digite aqui' size='md' />
                                </>
                            </GridItem>
                        </Grid>

                        <Grid templateColumns={{ base: 'repeat(1, 1fr)' }} gap={6} padding={{ base: '0 15px', md: '0 65px' }} mt='56px'
                            mb='65px'>
                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <>
                                    <Text mb='8px'
                                        fontFamily='Montserrat'
                                        fontStyle='normal'
                                        fontWeight='400'
                                        fontSize={'12px'}
                                        lineHeight='112%'
                                        color='#6E6B7B'
                                    >
                                        Se você selecionou 'outro', informe-nos em qual blockchain você está construindo
                                    </Text>
                                    <Input placeholder='Digite aqui' size='md' />
                                </>
                            </GridItem>

                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <Flex mt='27px'>
                                    <Checkbox colorScheme='green' />
                                    <Text ml='10px'>
                                        Marque esta caixa para receber comunicações do MyWayToPay. Você pode cancelar sua inscrição a qualquer momento.
                                        Cuidamos dos seus dados - consulte nossa{' '}
                                        <Link color='#00C2CB'
                                            cursor='pointer'
                                            fontFamily='Montserrat'
                                            fontStyle='normal'
                                            fontWeight='700'
                                            fontSize={{ base: '14px' }}
                                            lineHeight='21px'
                                            onClick={onOpen}
                                        >
                                            política de privacidade.*
                                        </Link>
                                    </Text>
                                </Flex>
                            </GridItem>

                            <GridItem w={{ base: '300px', md: '100%' }}>
                                <Button w='85px' h='35px' bg='#00C2CB'
                                    fontFamily='Montserrat'
                                    fontStyle='normal'
                                    fontWeight='500'
                                    fontSize={'12px'}
                                    lineHeight='15px'
                                    color='#FFFFFF'
                                    _hover={{
                                        backgroundColor: '#00C2CB'
                                    }}
                                >
                                    Enviar
                                </Button>
                            </GridItem>
                        </Grid>

                    </Box>
                </Center>
                {/* WE USE TO SHOW POLITIC OF PRIVACITY */}
                <>
                    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Política de privacidade</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio, aut enim debitis, molestiae dolor omnis nihil consequatur totam eum officiis non quod. Eveniet iure odio reprehenderit esse facere quos repellendus?
                            </ModalBody>

                            <ModalFooter>
                                <Button colorScheme='blue' mr={3} onClick={onClose}>
                                    Fechar
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </>
                {/* WE USE TO SHOW POLITIC OF PRIVACITY */}
            </Box>
            <Footer />
        </>
    );
}
