// ** Icons Import
import { Center, Flex, Grid, GridItem, Image, Link, Text } from "@chakra-ui/react";

import IconFooter1 from '../../assets/images/pages/iconFooter1.svg'
import IconFooter2 from '../../assets/images/pages/iconFooter2.svg'
import IconFooter3 from '../../assets/images/pages/iconFooter3.svg'
import IconFooter4 from '../../assets/images/pages/iconFooter4.svg'
import IconFooter5 from '../../assets/images/pages/iconFooter5.svg'
import IconFooter6 from '../../assets/images/pages/iconFooter6.svg'
import IconFooter7 from '../../assets/images/pages/iconFooter7.svg'
import GoblockchainFooter from '../../assets/images/pages/logoGoBlockchainFooter.svg'

const iconFooter = [
  {
    Image: IconFooter1,
    Link: 'https://chakra-ui.com'
  },
  {
    Image: IconFooter2,
    Link: 'https://chakra-ui.com'
  },
  {
    Image: IconFooter3,
    Link: 'https://github.com/goblockchain'
  },
  {
    Image: IconFooter4,
    Link: 'https://www.facebook.com/goblockchain'
  },
  {
    Image: IconFooter5,
    Link: 'https://www.youtube.com/c/GoBlockchain'
  },
  {
    Image: IconFooter6,
    Link: 'https://www.instagram.com/goblockchain/'
  },
  {
    Image: IconFooter7,
    Link: 'https://www.linkedin.com/company/goblockchain/mycompany/'
  }
]

const GridComponent = () => {
  return (
    <Grid templateColumns={{ base: 'repeat(4, 1fr)', md: 'repeat(7, 1fr)' }} gap={'0 23px'}>
      {iconFooter?.map((data, indexFooter) => {
        return (
          <GridItem w='100%' h='34px' key={indexFooter}>
            <Center w='40px' h='40px'>
              <Link href={data.Link} isExternal>
                <Image src={data.Image} />
              </Link>
            </Center>
          </GridItem>
        )
      })}
    </Grid>
  )
}

const Footer = () => {
  return (
    <>
      <Flex flexDir={{ sm: 'column', md: 'row' }} w="100%" bgGradient='linear-gradient( #00C2CB, #BDFED1)' >
        <Center h='100%' w='100%' mt='83px' mb='78px'>
          <GridComponent />
        </Center>
      </Flex>
      <Flex h='62px' w='100%' bg='#FFFFFF' flexDir='row' justifyContent='space-between'>
        <Center w='100%'>
          <Text fontFamily='Roboto'
            fontStyle='normal'
            fontWeight='400'
            fontSize='14px'
            lineHeight='16px'
            color='#454545'
          >
            Um produto feito pela
          </Text>
          <Image ml='12px' src={GoblockchainFooter} />
        </Center>
        <Center w='100%'>
          <Text fontFamily='Roboto'
            fontStyle='normal'
            fontWeight='400'
            fontSize={{ base: '12px', md: '16px' }}
            lineHeight='19px'
            textAlign='center'
            color='#A19D9D'
          >
            POLÍTICA DE PRIVACIDADE
          </Text>
          <Text fontFamily='Roboto'
            fontStyle='normal'
            fontWeight='400'
            fontSize={{ base: '12px', md: '16px' }}
            lineHeight='19px'
            textAlign='center'
            ml='35px'
            color='#A19D9D'
          >
            TERMOS DE USO
          </Text>
        </Center>
      </Flex>
    </>
  )
}

export default Footer
