// import React, { ReactElement, useState } from "react";

import { Flex, Center, Image, Text, Show } from "@chakra-ui/react";

import source from '../../assets/images/pages/solution.svg'
import sourceBASE from '../../assets/images/pages/solutionBASE.svg'

export default function Solution() {
    return (
        <Flex w="100%" h='100%' >
            <Center zIndex={1}
                flexDir="column"
                w="100%"
                mt={{ base: '430px', md: '190px', lg: '190px', xl: '283px', '2xl': '283px' }}
            >
                <Text textAlign='center'
                    fontFamily='Thyga Italic'
                    fontStyle='italic'
                    fontWeight='400'
                    fontSize={{ base: '45px', md: '90px', lg: '100px', '2xl': '120px' }}
                    lineHeight='112%'
                    color='#DBFE01'
                >
                    SOLUÇÃO
                </Text>
                <Text mt='12px'
                    w='100%'
                    maxW='624px'
                    textAlign='center'
                    fontFamily='Montserrat'
                    fontStyle='normal'
                    fontWeight='700'
                    fontSize={{ base: '20px', md: '28px', lg: '30px', '2xl': '30px' }}
                    lineHeight='112%'
                    color='#FFFFFF'
                >
                    Unificamos o melhor do mundo de pagamento Web2 na tecnologia Web3.
                </Text>
                <Image display={{ base: 'none', md: 'block' }}
                    mt={{ base: '31px', md: '108px', lg: '108px', xl: '108px', '2xl': '108px' }}
                    src={source}
                    w='100%'
                    maxW='1568px'
                    h='100%'
                    maxH='519px'
                    zIndex={1}
                />

                {/* WE USE TO SHOW BASE SIZE  */}
                <Show below='sm'>
                    <Image mt={{ base: '25px' }}
                        src={sourceBASE}
                        w='100%'
                        maxW='450px'
                        h='100%'
                    />
                </Show>
                {/* WE USE TO SHOW BASE SIZE  */}

            </Center >
        </Flex >
    );
}
