// import React from "react";
import { Link } from "react-router-dom";

import { Center, Flex, Image, Button, Show, Box } from "@chakra-ui/react";

//** logo
import Logo from '../../assets/images/logo/MWTP_LOGO 1.svg'

export const Nav = () => {

    return (
        <Flex justifyContent='space-between'
            w="100%"
            h={{ md: '80px', '2xl': '80px' }}
            flexDir={{ base: 'column', md: 'row' }}
            backgroundColor='#FFFFFF'
            position="fixed"
            top="0px"
            zIndex="40"
        >
            <Center w={{ base: '100%', md: '325px', '2xl': '352px' }} >
                <Image src={Logo} />
            </Center>

            <Center w="100%" maxW="570px" >
                <Flex w='100%'
                    padding={{ base: '0 20px', md: '0' }}
                    // bg='red'
                    mr={{ base: 0, md: '42px' }}
                    justifyContent='space-between'
                >
                    <Flex mt='9px'>
                        <Link to="/">
                            <Button color='#505050'
                                variant='link'
                                fontWeight='400'
                                fontSize={{ base: '12px', lg: '16px', '2xl': '16px' }}
                                lineHeight='20px'
                                fontStyle='normal'
                                fontFamily='Montserrat'
                            >
                                My WAY
                            </Button>
                        </Link>
                    </Flex>
                    <Button color='#505050'
                        variant='link'
                        fontWeight='400'
                        fontSize={{ base: '12px', lg: '16px', '2xl': '16px' }}
                        lineHeight='20px'
                        fontStyle='normal'
                        fontFamily='Montserrat'
                    >
                        Como funciona?
                    </Button>
                    <Button color='#505050'
                        variant='link'
                        fontWeight='400'
                        fontSize={{ base: '12px', lg: '16px', '2xl': '16px' }}
                        lineHeight='20px'
                        fontStyle='normal'
                        fontFamily='Montserrat'
                    >
                        Para Devs
                    </Button>
                    <Show above='sm'>
                        <Link to="join-us">
                            <Button
                                display={{ sm: 'none', md: 'block' }}
                                w={{ base: '133px', '2xl': '133px' }}
                                h='43px'
                                backgroundColor='#E06C82'
                                color='#FFFFFF'
                                variant='solid'
                                _hover={{ backgroundColor: '#E06C82' }}
                                fontWeight='400'
                                fontSize={{ base: '12px', lg: '22px', '2xl': '24px' }}
                                lineHeight='112%'
                                textTransform='uppercase'
                                fontStyle='normal'
                                fontFamily='Thyga Italic'
                            >
                                Juntar-se  !!
                            </Button>
                        </Link>
                    </Show>

                </Flex>
            </Center>
            <Box display={{ sm: 'block', md: 'none' }} mb='5px'>
                <Center >
                    <Link to="join-us">
                        <Button
                            mt='10px'
                            display={{ base: 'block', md: 'none' }}
                            w={{ base: '133px', '2xl': '133px' }}
                            h='43px'
                            backgroundColor='#E06C82'
                            color='#FFFFFF'
                            variant='solid'
                            _hover={{ backgroundColor: '#E06C82' }}
                            fontWeight='400'
                            fontSize={{ base: '12px', lg: '22px', '2xl': '24px' }}
                            lineHeight='112%'
                            textTransform='uppercase'
                            fontStyle='normal'
                            fontFamily='Thyga Italic'
                        >
                            Juntar-se ??
                        </Button>
                    </Link>
                </Center>
            </Box>
        </Flex>
    );
};
