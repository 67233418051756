// import React, { ReactElement, useState } from "react";

import { Flex, Center, Button, Image, Text, Box } from "@chakra-ui/react";

import GroupImage2 from '../../assets/images/pages/GroupImage2.svg'
import checkout from '../../assets/images/pages/myway_checkout.gif'

export default function Functionalities() {
    return (
        <Flex w="100%" h='100%' flexDir='column' >
            <Flex overflow='hidden'>
                <Image overflow='hidden'
                    src={GroupImage2}
                    w='100%'
                    maxW='1920px'
                    maxH='882px'
                    position='absolute'
                    mt={{ base: '550px', md: '370px', lg: '250px', xl: '70' }}
                />
            </Flex>
            <Box flexDir='column' w='100%' zIndex={1}>
                <Center w='100%' flexDir='column' mt={{ base: '60px', md: '80px', lg: '120px', xl: '140px' }}>
                    <Text
                        fontFamily='Thyga Italic'
                        fontStyle='normal'
                        fontWeight='400'
                        fontSize={{ base: '26px', md: '50px', lg: '50px', xl: '50px', '2xl': '50px' }}
                        lineHeight='112%'
                        color='#FFFFFF'
                        zIndex={5}
                    >
                        MEU CHECKOUT
                    </Text>
                    <Text w='100%'
                        maxW='453px'
                        fontFamily='Montserrat'
                        fontStyle='normal'
                        fontWeight='500'
                        fontSize={{ base: '20px' }}
                        lineHeight='112%'
                        color='#000000'
                        textAlign='center'
                    >
                        Um botão de pagamento para um checkout de uma página rápido, seguro e completo.
                    </Text>
                    <Image src={checkout} mt='47px' w={{ base: '350px', md: '750px', lg: '891px', '2xl': '891px' }} />
                    <Button mt={{ base: '96px', md: '96px', lg: '40px', xl: '40px', '2xl': '96px' }}
                        w={{ base: '250px', md: '382px', '2xl': '382px' }}
                        h={{ base: '77px', md: '77px' }}
                        backgroundColor='#FE98AB'
                        color='#FFFFFF'
                        _hover={{ backgroundColor: '#E06C82' }}
                        fontWeight='400'
                        fontSize={{ base: '25px', md: '34px', lg: '34px', xl: '34px', '2xl': '34px' }}
                        lineHeight='112%'
                        fontStyle='normal'
                        fontFamily='Thyga Italic'
                        boxShadow=' 9px 8px  #D04C9B'
                    >
                        JUNTAR-SE  AO MYWAY
                    </Button>
                </Center>
            </Box>
        </Flex>

    );
}
