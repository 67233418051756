import React from "react";

import {
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Center,
    Image
} from "@chakra-ui/react";

import imagebackgroudFAQ from '../../assets/images/pages/GroupFAQ.svg'

import { MinusIcon, AddIcon } from "@chakra-ui/icons";

const FAQITEMS = [
    {
        "title": "Pergunta 1",
        "content": "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Placeat aspernatur, repellendus voluptas iusto, voluptates fuga dolore dolor tenetur rem veritatis sint doloremque magnam rerum tempora. Exercitationem asperiores soluta consectetur ut."
    },
    {
        "title": "Pergunta 2",
        "content": "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Placeat aspernatur, repellendus voluptas iusto, voluptates fuga dolore dolor tenetur rem veritatis sint doloremque magnam rerum tempora. Exercitationem asperiores soluta consectetur ut."
    },
    {
        "title": "Pergunta 3",
        "content": "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Placeat aspernatur, repellendus voluptas iusto, voluptates fuga dolore dolor tenetur rem veritatis sint doloremque magnam rerum tempora. Exercitationem asperiores soluta consectetur ut."
    },
    {
        "title": "Pergunta 4",
        "content": "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Placeat aspernatur, repellendus voluptas iusto, voluptates fuga dolore dolor tenetur rem veritatis sint doloremque magnam rerum tempora. Exercitationem asperiores soluta consectetur ut."
    },
    {
        "title": "Pergunta 5",
        "content": "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Placeat aspernatur, repellendus voluptas iusto, voluptates fuga dolore dolor tenetur rem veritatis sint doloremque magnam rerum tempora. Exercitationem asperiores soluta consectetur ut."
    }
]

export const FAQ = () => {
    return (
        <Center w="100%" flexDir="column">
            <Image src={imagebackgroudFAQ} position='absolute'
                mt={{ base: '10px', md: '31px', lg: '31px', '2xl': '148px' }}
            />
            <Center mt={{ base: '50px', md: '150px', lg: '200px', xl: '250px', '2xl': '460px' }}
                flexDir="column" mb="78px"
                p="0px 120px"
                zIndex={1}
            >
                <Text fontFamily='Thyga Italic'
                    fontStyle='normal'
                    fontWeight='400'
                    fontSize={{ base: '35px', md: '70px', lg: '80px', xl: '80px', '2xl': '80px' }}
                    lineHeight='112%'
                    color='#00C2CB'
                >
                    PERGUNTAS FREQUENTES
                </Text>
                {FAQITEMS?.map((data, index) => {
                    return (
                        <Accordion key={index}
                            allowToggle
                            maxW="813px"
                            border="none"
                            mt={{ base: "25px", md: "38px" }}
                            w={{ base: "360px", sm: "450px", md: "730px", lg: "813px" }}
                        >
                            <AccordionItem
                                border="none"
                                borderRadius="15px"
                                boxShadow="0px 6px 23px rgba(0, 0, 0, 0.1)"
                                overflow="hidden"
                            >
                                {({ isExpanded }) => (
                                    <>
                                        <Text bg="#FFFFFF">
                                            <AccordionButton h="75px" _focus={{ outline: 0 }}>
                                                <Text
                                                    ml={{ base: "8px", md: "47px" }}
                                                    flex="1"
                                                    textAlign="left"
                                                    fontSize={{ base: "18px", md: "22px" }}
                                                    fontWeight="700"
                                                    lineHeight="112%"
                                                    fontFamily='Montserrat'
                                                    fontStyle='normal'
                                                    color='#6E6B7B'
                                                >
                                                    {data.title}
                                                </Text>
                                                {isExpanded ? (
                                                    <MinusIcon color='#E06C82' fontSize="22px" mr="27px" ml="10px" />
                                                ) : (
                                                    <AddIcon color='#E06C82' fontSize="22px" mr="27px" ml="10px" />
                                                )}
                                            </AccordionButton>
                                        </Text>
                                        <AccordionPanel pb={4} p="20px" bg="#FFFFFF">
                                            <Text flex="1"
                                                textAlign="left"
                                                fontSize={{ base: "18px", md: "20px" }}
                                                fontWeight="500"
                                                lineHeight="112%"
                                                p="0 25px 25px 47px"
                                                fontFamily='Montserrat'
                                                fontStyle='normal'
                                                color='#6E6B7B'
                                                dangerouslySetInnerHTML={{ __html: data.content }}
                                            />
                                        </AccordionPanel>
                                    </>
                                )}
                            </AccordionItem>
                        </Accordion>
                    )
                })}
            </Center>
        </Center>
    );
};
