import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../views/home";
import JoinUsPAGE from "../views/join-us";
import NotFound from '../views/not-found'

const Content = () => (
  <main className="Content">
    <Routes>
      <Route path="/" element={<Home />}></Route>
      {/* <Route path="/join-us"><JoinUsPAGE /></Route> */}
      <Route path="join-us" element={<JoinUsPAGE />} />
      <Route path="*" element={<NotFound />}></Route>
    </Routes>
  </main>
);

export default Content;
