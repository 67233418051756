import * as React from "react"
import { BrowserRouter as Router } from 'react-router-dom'
import { ChakraProvider, theme } from "@chakra-ui/react"

import './styles/App.css'
import Content from './router/Content.jsx'

function App() {
  return (
    <ChakraProvider theme={theme}>
      <>
        <Router>
          <Content />
        </Router>
      </>
    </ChakraProvider>
  );
}

export default App;