// import React, { ReactElement, useState } from "react";

import { Flex, Center, Grid, GridItem, Image, Text } from "@chakra-ui/react";

//** import from asstets logo icons
import ImagePartneShip1 from '../../assets/images/icons/strockcar-logo.svg'
import ImagePartneShip2 from '../../assets/images/icons/BUDDHA_SPA.svg'
import ImagePartneShip3 from '../../assets/images/icons/campari.svg'
import ImagePartneShip4 from '../../assets/images/icons/foodieslogo.svg'
import ImagePartneShip5 from '../../assets/images/icons/TresPontoZero-White.svg'
import ImagePartneShip6 from '../../assets/images/icons/Brognoli.svg'
import ImagePartneShip7 from '../../assets/images/icons/culte-logo-green.svg'
import ImagePartneShip8 from '../../assets/images/icons/FTM.svg'


/* WE USE TO SHOW BASE MD LG XL 2XL SIZE  */
const GridoptmationMap = [
    {
        image: ImagePartneShip1
    },
    {
        image: ImagePartneShip2
    },
    {
        image: ImagePartneShip3
    },
    {
        image: ImagePartneShip4
    },
    {
        image: ImagePartneShip5
    },
    {
        image: ImagePartneShip6
    },
    {
        image: ImagePartneShip7
    },
    {
        image: ImagePartneShip8
    }
]

const GridoptmationImagePartnership = () => {
    return (
        <Grid templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)' }} gap={{ base: '35px 55px', md: '55px 35px' }} >
            {GridoptmationMap?.map((data, indexMapImageShip) => {
                return (
                    <GridItem w='100%'
                        maxW='1133px'
                        key={indexMapImageShip}
                    >
                        <Center w='100%' h='100%'>
                            <GridItem  >
                                <Image w={{ base: '80px', md: '100%' }} maxW='226px' src={data.image} />
                            </GridItem>
                        </Center>
                    </GridItem>
                )
            })}
        </Grid>
    )
}
/* WE USE TO SHOW MD LG XL 2XL SIZE  */

export default function Partnership() {
    return (
        <Flex w="100%" flexDir='column'>
            <Text mt={{ base: '100px', md: '150px', lg: '200px', '2xl': '321px' }}
                textAlign={'center'}
                fontFamily='Thyga Italic'
                fontStyle='normal'
                fontWeight='400'
                fontSize={{ base: '35px', md: '60px', lg: '60px', xl: '60px', '2xl': '60px' }}
                lineHeight='112%'
                color='#DBFE01'
            >
                EMPRESAS QUE CONFIAM NA GENTE
            </Text>
            <Center w="100%" mt={{ base: '25px', md: '118px', lg: '118px', '2xl': '118px' }}>
                <GridoptmationImagePartnership />
            </Center>
        </Flex>

    );
}
