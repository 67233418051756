// import React, { ReactElement, useState } from "react";

import { Flex, Text, Center, Image } from "@chakra-ui/react";

import JoinUS from '../../assets/images/pages/Maskgroup.svg'

export default function JoinUs() {

    return (

        <Flex flexDir={{ base: "column", md: 'row' }} w="100%" justifyContent='space-between'
            mt={{ base: '80px', md: '316px', lg: '316px', '2xl': '316px' }}
            bg='#02A3B9'
        >
            <Center w='100%'>
                <Image src={JoinUS} />

            </Center>
            <Center flexDir='column' w='100%'>
                <Text w='100%'
                    maxW={{ base: '350px', md: '730px' }}
                    mt={{ base: '15px', md: 0 }}
                    fontFamily='Thyga Italic'
                    fontStyle='italic'
                    fontWeight='400'
                    fontSize={{ base: '35px', md: '70px' }}
                    lineHeight='112%'
                    color='#DBFE01'
                >
                    VOCÊ É MULTIPLO, SEU JEITO DE PAGAR TAMBÉM DEVE SER.
                </Text>

            </Center>
        </Flex>
    );
}
