// import React, { ReactElement, useState } from "react";

import { Flex, Center, Grid, GridItem, Text, Image, Hide, Show } from "@chakra-ui/react";

/* WE USE TO SHOW IN MD TO 2XL SIZE  */
import img1 from '../../assets/images/pages/4topicos_1.svg'
import img2 from '../../assets/images/pages/4topicos_2.svg'
import img3 from '../../assets/images/pages/4topicos_3.svg'
import img4 from '../../assets/images/pages/4topicos_4.svg'

/* WE USE TO SHOW IN BASE  SIZE  */
import img1BASE from '../../assets/images/pages/4topicos_1BASE.svg'
import img2BASE from '../../assets/images/pages/4topicos_2BASE.svg'
import img3BASE from '../../assets/images/pages/4topicos_3BASE.svg'
import img4BASE from '../../assets/images/pages/4topicos_4BASE.svg'

/* WE USE TO SHOW IN MD TO 2XL SIZE  */
const GridOptmationMD2XLMap = [
    {
        Title: 'Membros de comunidades',
        text: 'Para quem tem tokens parados na sua carteira e buscam mais utilidades para eles.',
        bg: '#E06C82',
        image: img1
    },
    {
        Title: 'PESSOAS FÍSICAS',
        text: 'Para quem quer fazer compras ou vendas no seu dia-a-dia utilizando cripto ou fiat. Ou até receber cashback em um único token.',
        bg: '#C05AFF',
        image: img2
    },
    {
        Title: 'EMPRESAS',
        text: 'Empresas que querem gerar benefícios com comunidades que tem o mesmo propósito que sua marca, gerando utilidade a tokens e engajando a comunidade.',
        bg: '#00C2CB',
        image: img3
    },
    {
        Title: 'DAOs',
        text: 'Que querem alcançar mais pessoas utilizando um meio de pagamento que suporta seus tokens e automatizar o split de pagamento em diferentes carteiras no ato da compra.',
        bg: '#7367F0',
        image: img4
    }
]

const GridOptmationMD2XLPerfil = () => {
    return (
        <Hide breakpoint='(max-width: 440px)'>
            <Grid
                templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)', lg: 'repeat(1, 1fr)', '2xl': 'repeat(2, 1fr)' }} gap='20px 53px'
                mt={{ base: '87px', md: '87px', lg: '87px', '2xl': '87px' }}  >
                {GridOptmationMD2XLMap?.map((data, indexMap) => {
                    return (
                        <Center>
                            <GridItem w={{ base: '350px', md: '555px' }}
                                h={{ base: '100%', md: '259px' }}
                                mb={{ base: '5px', md: 0 }}
                                bg={data.bg}
                                borderRadius='22px'
                                boxShadow={'0px 4px 20px rgba(53, 169, 174, 0.26'}
                                key={indexMap}
                            >
                                <Flex>

                                    <Center flexDir='column' padding={{ base: '0 10px', md: '0  25px' }}>
                                        <Text mt={{ base: '30px' }}
                                            fontFamily='Montserrat'
                                            fontStyle='normal'
                                            fontWeight='900'
                                            fontSize={{ base: '20px' }}
                                            lineHeight='24px'
                                            color='#FFFFFF'
                                            // textAlign='center'
                                            // justifyContent='start'
                                            w='100%'
                                            textTransform='uppercase'
                                            dangerouslySetInnerHTML={{ __html: data.Title }}
                                        />
                                        <Text mt={{ base: '30px' }}
                                            fontFamily='Montserrat'
                                            fontStyle='normal'
                                            fontWeight='700'
                                            fontSize={{ base: '16px' }}
                                            lineHeight='20px'
                                            color='#FFFFFF'
                                            // textAlign='center'
                                            w='100%'
                                            dangerouslySetInnerHTML={{ __html: data.text }}
                                            maxW='311px'
                                        />
                                    </Center>
                                    <Image src={data.image} />

                                </Flex>
                            </GridItem>
                        </Center>
                    )
                })}
            </Grid>
        </Hide>
    )
}
/* WE USE TO SHOW IN MD TO 2XL SIZE  */

/* WE USE TO SHOW IN BASE  SIZE  */
const GridOptmationBASEMap = [
    {
        Title: 'Membros de comunidades',
        text: 'Para quem tem tokens parados na sua carteira e buscam mais utilidades para eles.',
        bg: '#E06C82',
        image: img1BASE
    },
    {
        Title: 'PESSOAS FÍSICAS',
        text: 'Para quem quer fazer compras ou vendas no seu dia-a-dia utilizando cripto ou fiat. Ou até receber cashback em um único token.',
        bg: '#C05AFF',
        image: img2BASE
    },
    {
        Title: 'EMPRESAS',
        text: 'Empresas que querem gerar benefícios com comunidades que tem o mesmo propósito que sua marca, gerando utilidade a tokens e engajando a comunidade.',
        bg: '#00C2CB',
        image: img3BASE
    },
    {
        Title: 'DAOs',
        text: 'Que querem alcançar mais pessoas utilizando um meio de pagamento que suporta seus tokens e automatizar o split de pagamento em diferentes carteiras no ato da compra.',
        bg: '#7367F0',
        image: img4BASE
    }
]

const GridOptmationBASEPerfil = () => {
    return (
        <Show below="base">
            <Grid
                templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)', lg: 'repeat(1, 1fr)', '2xl': 'repeat(2, 1fr)' }} gap='20px 53px'
                mt={{ base: '87px', md: '87px', lg: '87px', '2xl': '87px' }}  >
                {GridOptmationBASEMap?.map((data, indexMapBASE) => {
                    return (
                        <Center>
                            <GridItem w={{ base: '350px', md: '555px' }}
                                h={{ base: '100%', md: '259px' }}
                                bg={data.bg}
                                borderRadius='22px'
                                boxShadow={'0px 4px 20px rgba(53, 169, 174, 0.26'}
                                key={indexMapBASE}
                            >
                                <Center flexDir='column'>

                                    <Center flexDir='column' padding={{ base: '0 10px', md: '0  25px' }}>
                                        <Text mt={{ base: '30px' }}
                                            fontFamily='Montserrat'
                                            fontStyle='normal'
                                            fontWeight='900'
                                            fontSize={{ base: '20px' }}
                                            lineHeight='24px'
                                            color='#FFFFFF'
                                            // textAlign='center'
                                            // justifyContent='start'
                                            w='100%'
                                            textTransform='uppercase'
                                            dangerouslySetInnerHTML={{ __html: data.Title }}
                                        />
                                        <Text mt={{ base: '30px' }}
                                            fontFamily='Montserrat'
                                            fontStyle='normal'
                                            fontWeight='700'
                                            fontSize={{ base: '16px' }}
                                            lineHeight='20px'
                                            color='#FFFFFF'
                                            // textAlign='center'
                                            w='100%'
                                            dangerouslySetInnerHTML={{ __html: data.text }}
                                            maxW='311px'
                                        />
                                    </Center>
                                    <Image src={data.image} mt='12px' />

                                </Center>
                            </GridItem>
                        </Center>
                    )
                })}
            </Grid>
        </Show>
    )
}
/* WE USE TO SHOW IN BASE  SIZE  */
export default function PerfilMyWay() {
    return (
        <Flex w="100%" flexDir='column' overflow='hidden' >
            <Center zIndex={1}
                flexDir='column'
                w="100%"
                mt={{ base: '80px', md: '200px', lg: '200px', xl: '200px', '2xl': '319px' }}
            >
                <Text textAlign={'center'}
                    fontFamily='Thyga Italic'
                    fontStyle='normal'
                    fontWeight='400'
                    fontSize={{ base: '40px', md: '60px', lg: '60px', xl: '60px', '2xl': '60px' }}
                    lineHeight='112%'
                    color='#DBFE01'
                >
                    COMO  PODEMOS  BENEFICIAR VOCÊ?
                </Text>

                {/* WE USE TO SHOW IN MD TO 2Xl SIZE  */}
                <GridOptmationMD2XLPerfil />
                {/* WE USE TO SHOW IN BASE and 2Xl SIZE  */}

                {/* WE USE TO SHOW IN BASE  SIZE  */}
                <GridOptmationBASEPerfil />
                {/* WE USE TO SHOW IN BASE  SIZE  */}

            </Center>
        </Flex>

    );
}
